<template>
	<div class="icon">
		<Home v-if="iconName === 'home'" />
		<Close v-if="iconName === 'close'" />
		<burgerComp v-if="iconName === 'burger'" />
		<Calendar v-if="iconName === 'calendar'" />
		<Driver v-if="iconName === 'driver'" />
		<Element v-if="iconName === 'element'" />
		<Message v-if="iconName === 'message'" />
		<SettingTwo v-if="iconName === 'setting'" />
		<TaskSquare v-if="iconName === 'task'" />
		<Union v-if="iconName === 'union'" />
		<Pluse v-if="iconName === 'pluse'" />
		<FedMedOrg v-if="iconName === 'fed-med-org'" />
		<ResultNok v-if="iconName === 'result-nok'" />
		<Diagram v-if="iconName === 'diagram'" />
		<DiagramGray v-if="iconName === 'diagramGray'" />
		<Dictionary v-if="iconName === 'dictionary'" />
		<Users v-if="iconName === 'users'" />
		<ActiveOsMembers v-if="iconName === 'active'" />
		<AssingIconMO v-if="iconName === 'assign'" />
		<InfoMO v-if="iconName === 'info'" />
		<ControlEvent v-if="iconName === 'control'" />
		<Send class="bg-rect" v-if="iconName === 'send'" />
		<Pen class="bg-rect" v-if="iconName === 'pen'" />
		<AddPluse v-if="iconName === 'add'" />
		<Exel v-if="iconName === 'exel'" />
		<Load v-if="iconName === 'load'" />
		<Shevron class="bg-stroke" v-if="iconName === 'shevron'" />
		<ShevronBig v-if="iconName === 'shevronBig'" />
		<User v-if="iconName === 'user'" />
		<Trash v-if="iconName === 'trash'" />
		<TrashBlue v-if="iconName === 'trash-blue'" />
		<TrashForBtn v-if="iconName === 'trash_for_btn'" />
		<UpLoad v-if="iconName === 'upload'" />
		<Confirmed v-if="iconName === 'confirmed'" />
		<NotConfirmed v-if="iconName === 'notConfirmed'" />
		<Delete v-if="iconName === 'delete'" />
		<Question v-if="iconName === 'question-white'" />
		<ViewGrey v-if="iconName === 'view-grey'" />
		<CopyGrey v-if="iconName === 'copy-grey'" />
		<ArchiveGrey v-if="iconName === 'archive-grey'" />
		<PublishGrey v-if="iconName === 'publish-grey'" />
		<DragDrop v-if="iconName === 'drag-drop'" />
		<squareClose class="bg" v-if="iconName === 'square-close'" />
		<squareOk class="bg" v-if="iconName === 'square-ok'" />
	</div>
</template>

<script>
import AssingIconMO from '../assets/img/assingIconMO.svg';
import InfoMO from '../assets/img/InfoMO.svg';
import ControlEvent from '../assets/img/ControlEvent.svg';
import ActiveOsMembers from '../assets/img/ActiveOsMembers.svg';
import squareClose from '../assets/img/squareClose.svg';
import squareOk from '../assets/img/squareOk.svg';
import burgerComp from '../assets/img/burger.svg';
import Shevron from '../assets/img/shevron.svg';
import Trash from '../assets/img/trash.svg';
import TrashBlue from '../assets/img/trash-blue.svg';
import TrashForBtn from '../assets/img/trash_for_btn.svg';
import UpLoad from '../assets/img/upload.svg';
import User from '../assets/img/user.svg';
import ShevronBig from '../assets/img/shevronBig.svg';
import Load from '../assets/img/load.svg';
import Send from '../assets/img/pen.svg';
import Pen from '../assets/img/send.svg';
import Close from '../assets/img/close.svg';
import Home from '../assets/img/home-2.svg';
import Calendar from '../assets/img/calendar.svg';
import Driver from '../assets/img/driver.svg';
import Element from '../assets/img/element-3.svg';
import Message from '../assets/img/message.svg';
import SettingTwo from '../assets/img/setting-two.svg';
import TaskSquare from '../assets/img/task-square.svg';
import Union from '../assets/img/Union.svg';
import Pluse from '../assets/img/pluse.svg';
import FedMedOrg from '../assets/img/fed-med-org.svg';
import ResultNok from '../assets/img/result-nok.svg';
import Diagram from '../assets/img/diagram.svg';
import DiagramGray from '../assets/img/diagram-gray.svg';
import Dictionary from '../assets/img/dictionary.svg';
import Users from '../assets/img/users.svg';
import AddPluse from '../assets/img/addPluse.svg';
import Exel from '../assets/img/exel.svg';
import Confirmed from '../assets/img/confirmed.svg';
import NotConfirmed from '../assets/img/notConfirmed.svg';
import Delete from '../assets/img/delete.svg';
import Question from '../assets/img/question-mark-white.svg';
import ViewGrey from '../assets/img/view-grey.svg';
import CopyGrey from '../assets/img/copy-grey.svg';
import ArchiveGrey from '../assets/img/archive-grey.svg';
import PublishGrey from '../assets/img/publish-grey.svg';
import DragDrop from '../assets/img/drag-drop.svg';

export default {
	name: 'Icons',
	props: {
		iconName: String
	},
	components: {
		AssingIconMO,
		InfoMO,
		ControlEvent,
		ActiveOsMembers,
		Shevron,
		squareClose,
		squareOk,
		User,
		UpLoad,
		Trash,
		TrashBlue,
		TrashForBtn,
		ShevronBig,
		Exel,
		Load,
		AddPluse,
		Send,
		Pen,
		burgerComp,
		Close,
		Home,
		SettingTwo,
		Union,
		TaskSquare,
		Message,
		Pluse,
		Element,
		Driver,
		Calendar,
		FedMedOrg,
		ResultNok,
		Diagram,
		DiagramGray,
		Dictionary,
		Users,
		Confirmed,
		NotConfirmed,
		Delete,
		Question,
		ViewGrey,
		CopyGrey,
		ArchiveGrey,
		PublishGrey,
		DragDrop
	}
};
</script>

<style scoped>
.white rect {
	fill: transparent;
}

.white path {
	fill: rgb(51, 119, 255);
	stroke: rgb(51, 119, 255);
}

.icon .bg:hover path {
	fill: rgb(51, 119, 255);
}

.icon .bg-rect:hover rect {
	fill: rgb(51, 119, 255);
}

.icon .bg-stroke path {
	stroke: rgb(51, 119, 255);
}
</style>
